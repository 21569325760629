.rhap_container {
    background: #000 !important;
}
.rhap_current-time, .rhap_time {
    color: #CCC !important;
}
.rhap_progress-bar {
    background: #CCC !important;
}
.rhap_header {
    color: #CCC;
    text-align: center;
}